.spinner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgba(45, 75, 72, 0.6);
  min-width: 80px;
  min-height: 80px;
}

.spinner {
  width: 80px;
  height: 80px;

  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 10px solid #c0c9c8;
    border-radius: 50%;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 10px solid rgba(#fff, 0.25);
    border-top-color: #425d5a;
    border-radius: 50%;
    animation: rotation 0.8s ease infinite;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
