@import '~react-toastify/dist/ReactToastify.css';

.main {
  min-height: 100svh;
  background-color: #f4f6f6;

  &_body {
    position: relative;
    overflow: hidden;
    min-height: calc(100svh - 64px);
  }

  &_content {
    position: relative;
    padding-left: 0;
    overflow: auto;
    transition: padding-left 0.5s;
    min-height: calc(100svh - 64px);

    @media screen and (min-width: 800px){
      padding-left: 48px;
    }

    &_small {
      padding-left: 264px;
    }

    @media screen and (max-width: 799px){
      padding-left: 0;
    }
  }
}

.full_page {
  width: 100%;
  height: 100svh;
  position: relative;
}

.button {
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  line-height: 20px;

  padding: 4px 12px;
  transition: all 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }

  &_small {
    padding: 2px 12px;
  }
  &_large {
    padding: 6px 16px;
    font-size: 14px;

    span {
      font-size: 14px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
  &_full {
    width: 100%;
  }

  &_primary {
    background-color: #425d5a;
    color: #f4f6f6;
    svg path {
      fill: #f4f6f6;
    }


    &:disabled {
      pointer-events: none;
      background-color: #819391;
      color: #D5DBDA;
    }

    &:focus {
      outline: 1px solid #fcb11c;
    }

    &:hover {
      background-color: #6c817f;
    }
    &:active {
      background-color: #2d4b48;
    }
  }

  &_secondary {
    background-color: #fcb933;
    color: #425d5a;

    svg path {
      fill: #425d5a;
    }

    &:disabled {
      pointer-events: none;
      background-color: #FDD077;
      color: #819391;
    }

    &:hover {
      background-color: #fdc860;
    }
    &:active {
      background-color: #fcb11c;
    }
  }

  &_gray {
    background-color: rgba(66, 93, 90, 0.16);
    color: #425d5a;

    svg path {
      fill: #425d5a;
    }


    &:disabled {
      pointer-events: none;
      background-color: rgba(66, 93, 90, 0.10);
      color: #819391;
    }

    &:hover {
      background-color: #c0c9c8;
    }
    &:active {
      background-color: #abb7b6;
    }
  }
}

.Toastify {
  z-index: 1000002;
  position: relative;
  max-width: 100vw;
  &__toast {
    box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 8px;
    min-height: 34px;
    &-body {
      margin: 0;
      padding: 0;
      max-width: 100%;
    }
    &--error {
      background: #ffc8b9;
      color: white;
    }
    &--success {
      background: #E3FCEF;
      color: #425D5A;
    }
  }
  &__close-button {
    display: none;
  }
  &__toast-container {
    z-index: 10002;
    max-width: 320px;
    width: 100%;

    @media screen and (max-width: 480px){
      max-width: calc(100vw - 30px);
      left: 15px;
      right: 15px;
      top: 10px;
    }
  }

  &__progress-bar--error {
    background: #FC8B9F;
  }

  &__progress-bar--error {
    background: #FC8B9F;
  }
  &__progress-bar--success {
    background: #425D5A;
  }
  &__progress-bar {
   opacity: 0 !important;
  }

  &__toast-icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    svg {
      fill: #425D5A;
    }
  }

  &__toast-body > div:last-child {
    color: #425D5A;
  }
}
