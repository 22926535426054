.lrd-ui--error {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &_wrap{
      max-width: 400px;
    }
  }
  &__image {
    max-width: 372px;
    width: 100%;
    margin: 10px;

    img {
      max-width: 100%;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
  }
  &__status {
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    &__code {
      margin-right: 8px;
      color: #2d4b48;
      display: inline-block;
    }
    &__message {
      color: #576f6d;
      display: inline-block;
      word-break: break-word;
    }
  }
  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #576f6d;
    margin-bottom: 16px;
    max-width: 600px;

    p, li{
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #6C817F;
    }
    ul {
      padding-left: 20px;
      list-style: disc;
    }
  }
  &__actions {
    display: flex;
    .lrd-db__button_small {
      &:not(:last-child) {
        margin-right: 8px;
      }
      padding: 2px 12px;
    }
  }

  &__description{
    &_messageWithLink {
      max-width: 360px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #576F6D;
      margin-bottom: 16px;
      a {
        color: #007FC4;
        text-decoration: underline;
      }
    }
  }
}
