.lrd-ui--cookie-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: flex;
  align-items: center;
  padding: 6px;
  &__message {
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    line-height: 16px;
    gap: 4px;
    flex-wrap: wrap;
    text-align: center;
    a {
      color: #0197F6;
      text-decoration: underline;
    }
    margin-right: 8px;
  }
  &__close {
    margin-left: auto;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    &, svg {
      flex-shrink: 0;
      path {
        fill: white;
        fill-opacity: 0.54;
      }
    }
  }
}