.labeled-select {
  display: flex;
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  sup {
    color: red;
  }
  &__label {
    position: absolute;
    top: -6px;
    left: 6px;
    font-size: 12px;
    line-height: 10px;
    background: white;
    color: #425d5a;
    padding: 0 4px;
    z-index: 1;
    border-radius: 2px;
    display: flex;
    white-space: nowrap;
  }
  &__error {
    margin-top: 3px;
    color: red;
    font-size: 10px;
    word-break: break-word;
  }
  &_error {
    .labeled-select__wrapper {
      div[class$='-control'] {
        border-color: red !important;
      }
    }
  }
  .lrd-ui-select {
    &__control {
      & > div {
        padding-top: 0;
        padding-bottom: 0;
      }
      border-radius: 4px;
      min-height: 30px;
      background: white;
      &--is-disabled {
        background: #f6f6f6;
      }
      border-color: #d5dbda;
      &--is-focused {
        border-color: #fcb11c;
        &:hover {
          border-color: #fcb11c;
        }
      }
      box-shadow: none;
    }
    &__placeholder {
      font-size: 12px;
      color: #819391;
    }
    &__input {
      color: #212121;
      font-size: 12px;
    }
    &__single-value {
      color: #425d5a;
      font-size: 12px;
      line-height: 13px;
    }
    &__multi-value {
      font-size: 12px;
      border-radius: 4px;
      margin: 2px 4px 2px 0;
      background: #d5dbda;
      color: #425d5a;
      padding: 1px 8px;
      div {
        //padding: 5px 2px 5px 14px;
        &:last-child {
          //padding: 2px 10px 2px 0;
          background: transparent;
          svg {
            height: 18px;
            width: 18px;
            fill: #425d5a;
            &:hover {
              fill: #425d5a;
              cursor: pointer;
            }
          }
        }
      }
      &__label {
        font-size: 12px;
        line-height: 20px;
        color: #425d5a;
        padding: 0;
      }
      &__remove {
        border-radius: 50px;
        background: transparent;
        padding: 0;
        margin-right: -6px;
      }
      &--is-disabled {
        background: #dfe1e6;
        &,
        div {
          color: #425d5a;
        }
        div:last-child svg {
          height: 0;
          width: 0;
          fill: transparent;
        }
        .lrd-ui-select__multi-value__remove {
          margin-right: 0;
        }
      }
    }
    &__option {
      color: #425d5a;
      min-height: 28px;
      font-size: 12px;
      line-height: 20px;
      padding: 3px 12px;
      cursor: pointer;
      background: white;
      &--is-focused {
        background: #d5dbda;
      }
      &--is-selected {
        background: #fee0a4;
      }
    }
    &__menu {
      margin-top: 0;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      z-index: 5;
      padding: 2px 0;
      box-shadow: none;
    }
    &__menu-portal {
      z-index: 2;
    }
    &__indicators-container {
      & > div {
        padding: 6px;
      }
    }
    &__indicator {
      padding: 1px 4px;
    }
    &__indicator-separator {
      display: none;
    }
  }
  &__select {
    width: 100%;
  }

  .lrd-ui-select__dropdown-indicator {
    width: 20px;
    svg {
      transition: all 0.1s;
      transform: rotate(0deg);
      path {
        fill: rgb(129, 147, 145);
      }
    }
  }
  .lrd-ui-select__control--menu-is-open .lrd-ui-select__dropdown-indicator {
    svg {
      transform: rotate(180deg);
      path {
        fill: #425d5a;
      }
    }
  }
}

.labeled-select__select.labeled-select__select {
  &--small {
    .lrd-ui-select {
      &__control {
        min-height: 24px;
      }
      &__multi-value {
        padding: 0 8px;
        &__label {
          line-height: 18px;
        }
      }
    }
    div[class$='-Input'] {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
    }
  }
  &--medium {
    .lrd-ui-select {
      &__control {
        min-height: 28px;
      }
    }
    div[class$='-Input'] {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
    }
  }
  &--large {
    .lrd-ui-select {
      &__placeholder {
        font-size: 14px;
      }
      &__single-value{
        font-size: 14px;
      }
      &__control {
        min-height: 32px;
      }
      &__multi-value {
        padding: 2px 8px;
        &__label {
          font-size: 14px;
          padding: 1px 0;
        }
      }
    }
  }
  &--no-dropdown-indicator {
    .lrd-ui-select__dropdown-indicator {
      display: none;
    }
  }
}
