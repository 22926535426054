.headerMain {
  position: relative;
  z-index: 10;
  background-color: #2d4b48;
  box-shadow:
    0 1px 8px 0 rgba(0, 0, 0, 0.2),
    0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 3px 3px 0 rgba(0, 0, 0, 0.14);
  padding: 12px 12px 16px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &_menu {
    line-height: 0;
    margin-right: 12px;
    button {
      line-height: 0;
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
  &_logo {
    line-height: 0;
    svg {
      height: 36px;
      width: 178px;

      @media screen and (max-width: 480px){
        width: 140px;
        height: 32px;
      }
    }
    path {
      fill: #ffffff;
    }
  }
  &_right {
    button {
    }
  }
}
