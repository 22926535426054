.change_lang {
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 40px 4px 12px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
    position: relative;

    svg {
      width: 24px;
      height: 24px;
      path {
        transition: all 0.3s;
        fill: #425d5a;
      }
    }
    span {
      left: 34px;
      position: absolute;
      padding-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #425d5a;
      transition: all 0.3s;
    }

    &.short {

      @media screen and (min-width: 800px) {
        padding: 4px 6px;
      }
    }

    &:hover,
    &.active {
      background-color: #819391;
      svg path {
        fill: #ffffff;
      }
      span {
        color: #ffffff;
      }
    }
  }
}

.tooltip-container {
  z-index: 10;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 4px 5px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  padding: 4px;
  width: 172px;

  button {
    cursor: pointer;
    text-align: start;
    display: block;
    width: 100%;
    border: none;
    border-radius: 4px;
    padding: 4px 4px 4px 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: #ffffff;
    transition: all 0.1s;
    color: #425d5a;

    &.change_lang_active {
      background-color: #fcb933;
    }
    &:hover {
      color: #FFFFFF;
      background: #819391;
    }
  }
}
