.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 4px;

  @media screen and (max-width: 520px){
    padding: 16px 12px 4px;
  }

  &_logo {
    line-height: 0;
    svg path{
      fill: #2D4A48;
    }

    @media screen and (max-width: 520px){
      max-width: 144px;

      svg {
        width: 100%;
      }
    }
  }

  &_switcher{
    display: flex;
    align-items: center;
    justify-content: center;

    .button{
      margin-right: 4px;
    }
  }
}
