.langSwitcher {
  .lrd-ui-select__input {
    // display: none;
  }
  &.labeled-select {
    .lrd-ui-select__control {
      background-color: #2d4b48;
    }
    .lrd-ui-select__single-value {
      color: #f4f6f6;
    }
    .lrd-ui-select__dropdown-indicator svg path {
      fill: #f4f6f6;
    }
  }
}
