.sidebar {
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 4px 5px 0 rgba(0, 0, 0, 0.14);
  width: 264px;
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: #ffffff;
  transform: translateX(-110%);
  transition: all 0.5s;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  @media screen and (min-width: 800px){
    width: 48px;
    transform: translateX(0);
  }

  &_bodyDrop {
    position: absolute;
    left: 48px;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: rgba(45, 75, 72, 0.87);
    opacity: 0;
    transition: opacity 0.5s;

    @media screen and (min-width: 800px){
      transition: opacity 0.5s, left 0.5s;
    }
  }

  &_open {
    transform: translateX(0);

    @media screen and (min-width: 800px){
      width: 264px;
    }

    @media screen and (max-width: 799px){
      transform: translateX(-110%);
    }
  }

  &_top,
  &_bottom {
    width: 100%;
  }

  &_user {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 6px;
    border-bottom: 1px solid #d5dbda;
    position: relative;
    overflow: hidden;

    &_info {
      position: relative;
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;
      overflow: hidden;
    }
    &_avatar {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      background-color: #f3f3f3;
      position: relative;
      overflow: hidden;

      svg {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        position: absolute;
        max-width: 200%;
        max-height: 200%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &_detail {
      position: absolute;
      left: 36px;
      padding: 0 8px;
      width: calc(100% - 36px);
    }
    &_name {
      color: #425d5a;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &_email {
      color: #6c817f;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &_actions {
      position: absolute;
      left: 220px;

      button {
        cursor: pointer;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
  }

  &_nav {
    width: 100%;
    padding: 8px 0;
    &_icon {
      svg {
        width: 24px;
        height: 24px;
        line-height: 0;
        path {
          fill: #2d4b48;
          transition: all 0.3s;
        }
      }
    }
    &_label {
      left: 40px;
      width: 216px;
      position: absolute;
      padding: 0 0 0 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #425d5a;
      transition: all 0.3s;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 4px;
      overflow: hidden;
      padding: 8px 12px;
      text-decoration: none;
      transition: all 0.3s;
      position: relative;

      &:hover {
        background-color: #2d4b48;
        .sidebar_nav_icon path {
          fill: #ffffff;
        }
        .sidebar_nav_label {
          color: #ffffff;
        }
      }

      &.active {
        background-color: #fdc860;
        .sidebar_nav_icon path {
          fill: #425d5a;
        }
        .sidebar_nav_label {
          font-weight: 500;
          color: #425d5a;
        }
      }
    }
  }

  &_bottom {
    border-top: 1px solid #d5dbda;
    padding: 4px 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &_mobile_open {
    @media screen and (max-width: 1024px){
      transform: translateX(0);
    }
    .sidebar_bodyDrop{

      left: 0;
      @media screen and (max-width: 799px) {
        left: 264px;
      }
      opacity: 1;
      right: -100vw;
      background-color: rgba(45, 75, 72, 0.87);
    }
  }
}
